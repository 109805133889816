import { FlagOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { navigate } from "gatsby";
import { filter, map } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  binanceWordImg,
  ftxWhiteImg,
  tokocryptologoImg,
} from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { AllExchangePlatforms } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import HighFrequencyPopUp from "../components/HighFrequencyPopUp";

// markup
const RobotPage = (props) => {
  const { t } = useTranslation();
  // const location = useLocation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [futurePopUp, setFuturePopUp] = useState(false);
  const [highPopUp, setHighPopUp] = useState(false);
  const [hfType, setHfType] = useState(1);
  const [profile, setProfile] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState();
  // const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getProfiles(1, 0, {
      _id: get(props.user, "user.profile._id"),
    })
      .then((res) => {
        setProfile(get(res, "data[0]"));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const platforms = [
    {
      title: "binance",
      value: "binance",
      img: binanceWordImg,
      path: routes.highFrequency.to({ type: 3 }),
    },
    {
      title: "FTX",
      value: "ftx",
      img: ftxWhiteImg,
      path: "",
    },
    {
      title: "Tokocrypto",
      value: "tokocrypto",
      img: tokocryptologoImg,
    },
  ];

  return (
    <>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 机器人策略{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                {t("robot", sourceKey.highFrequency)}
              </div>
            }
            showBack={true}
          >
            <div className="px-10 pt-5 pb-42">
              {/* <div onClick={() => { setFuturePopUp(true) }} className="my-4 rounded bg-white box-shadow-center p-3  text-red-500">
                合约策略 (测试当中）<br></br>Futures (Under Testing)
              </div> */}
              <span className="inline-block font-semibold text-base">
                {" "}
                {/* 高频网格交易机器人  */}
                {t("highFrequencyBots", sourceKey.highFrequency)}
              </span>

              <div className="my-3 flex justify-start">
                {/* 24/7
                网格交易加密货币机器人帮助客户使用已证实有效的高频自动低买高卖策略。不同的设定适用于不同的需求。 */}
                <br />
                {t("robotPageDesc1", sourceKey.highFrequency)}
              </div>
              <br />

              <div className="pt-2" style={{ height: "105vh" }}>
                <div className="">
                  {map(AllExchangePlatforms, (item, index) => {
                    let platform = filter(platforms, (p, index) => {
                      return get(p, "value") === item;
                    });
                    platform = platform[0];

                    return (
                      // <Link
                      //     to={get(item,"path")}
                      //     state={{ prevPath: location.pathname }}
                      // >
                      <div
                        className="mt-4 py-2 rounded-2xl flex justify-start touchbox-bg-color cursor-pointer"
                        onClick={() => {
                          setHighPopUp(true);
                          setHfType(3);
                          setSelectedPlatform(platform.value);
                        }}
                      >
                        <div className="justify-center items-center flex my-2 py-4 pl-6">
                          <img
                            src={get(platform, "img")}
                            style={{ width: 168, height: 36 }}
                          />
                        </div>
                      </div>
                      // </Link>
                    );
                  })}
                </div>

                
                {get(profile, "enableCopyTrade") === 1 && (
                  <div className="" style={{ marginTop: "150px" }}>
                    <div
                      className="mt-4 py-2 rounded-2xl flex justify-start touchbox-bg-color cursor-pointer"
                      onClick={() => {
                        // setHighPopUp(true);
                        // setHfType(3);
                        // setSelectedPlatform(platform.value);
                      }}
                    >
                      <div className="justify-center items-center flex my-2 py-4 pl-6">
                        <span>
                          <FlagOutlined style={{ fontSize: 50 }} />
                        </span>
                        <span className="pl-4" style={{ fontSize: "30px" }}>
                          CopyTrade
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                
              </div>

              {/* <div
                onClick={() => {
                  setHighPopUp(true);
                  setHfType(3);
                }}
                className="my-4 rounded bg-white box-shadow-center p-3 border-4 border-black cursor-pointer text-black"
              >
                <strong>HF 3 </strong>
                <br />
                无限网格策略，适合顾客自设天地长单，灵活可变的长期投资方案
                <br />
                Infinity grid trading strategy, long term investment with
                maximum flexibility
              </div> */}
            </div>
          </NavHeader>

          <React.Fragment>
            <HighFrequencyPopUp
              visible={highPopUp}
              onClose={() => {
                setHighPopUp(false);
              }}
              onOk={() => {
                setHighPopUp(false);
                navigate(
                  routes.highFrequency.to({
                    type: hfType,
                    platform: selectedPlatform,
                  })
                );
              }}
            ></HighFrequencyPopUp>
          </React.Fragment>
        </Spin>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
