import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import RobotPage from "../components/robot/pages/RobotPage"


// markup
const Robot = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <RobotPage />
    </React.Fragment>
  )
}

export default Robot
