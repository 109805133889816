import { useLocation } from "@reach/router";
import { Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { loginSuccessful } from "../../../redux/actions/user-actions";

// markup
const MessageModal = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleOk() {
    if (props.onOk) {
      props.onOk();
    }
  }

  const _renderContent = () => {
    return (
      <div className="lightgreybox-bg-color rounded-3xl px-5 py-12">
        <div className="font-semibold my-3">
          {/* 温馨提醒：<br /> */}
          {t("gentleReminder", sourceKey.highFrequency)}:
        </div>
        <div className="my-3 flex justify-start">
          {/* 高频策略，是一个稳定性的网格交易模式，逢低买入，涨高卖出。让用户能够稳定的赚取波段式盈利，于用户盈利时本平台才收取20% 的服务费。<br /> */}
          {t("hfdesc1", sourceKey.highFrequency)}
        </div>
        <div className="my-3 flex justify-start">
          {/* 下单后用户不允许手动在交易所操作相同交易对的买卖，一旦发生此操作， 系统会自动关闭改交易对并根据下单资金抽取5% 的最高服务费<br /> */}
          {t("hfdesc2", sourceKey.highFrequency)}
        </div>
        <div className="my-3 flex justify-start">
          {/* 请根据自身经济情况来选择最合适配套<br /> */}
          {t("hfdesc3", sourceKey.highFrequency)}
        </div>
        <div className="my-10">
          <Button
            block
            className="main-button-color input-border'"
            size="large"
            onClick={(e) => {
              handleOk();
            }}
          >
            {t("confirm")}
          </Button>
        </div>
        <div className="my-3 ">
          {/* 如有问题可联系客服<br /> */}
          {/* {t("hfdesc4", sourceKey.highFrequency)} */}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <MediaQuery maxWidth={670}>
          <Modal
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="80%"
            mask
            footer={null}
            className=""
            visible={visible}
            onCancel={() => {
              close();
            }}
          >
            {_renderContent()}
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={671}>
          <Modal
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="50%"
            mask
            footer={null}
            className=""
            visible={visible}
            onCancel={() => {
              close();
            }}
          >
            {_renderContent()}
          </Modal>
        </MediaQuery>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
